{
  "possibleTypes": {
    "AnyNotification": [
      "OrderReceivedPhaseChangedNotification"
    ],
    "Entity": [
      "Contact",
      "Me",
      "OrderReceived",
      "OrderReceivedItem",
      "Person",
      "Phase",
      "PhaseGroup",
      "Product",
      "Resource",
      "Task",
      "TimeEntry",
      "TimeEstimate",
      "User",
      "WebpushSubscription"
    ],
    "EnumEntity": [
      "Activity",
      "ContactType",
      "DeliveryType",
      "PaymentType"
    ],
    "Notification": [
      "OrderReceivedAssemblyAssignedToNotification",
      "OrderReceivedDeliveryAssignedToNotification",
      "OrderReceivedExpeditionAssignedToNotification",
      "OrderReceivedMissingPackagingAssignedToNotification",
      "OrderReceivedPackagingAssignedToNotification",
      "OrderReceivedPhaseChangedNotification",
      "ProductAssignedNotification",
      "ProductCommentCreatedNotification",
      "ProductCommentMentionNotification"
    ]
  }
}