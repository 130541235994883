import type { Logger } from '@engined/core/services/logger.js';
import type { ErrorInfo } from 'react-dom/client';

const BROWSER = {
  messenger: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
  facebook: /\bFB[\w_]+\//,
  twitter: /\bTwitter/i,
  line: /\bLine\//i,
  wechat: /\bMicroMessenger\//i,
  puffin: /\bPuffin/i,
  miui: /\bMiuiBrowser\//i,
  instagram: /\bInstagram/i,
  chrome: /\bCrMo\b|CriOS|Android.*Chrome\/[.0-9]* (Mobile)?/,
  safari: /Version.*Mobile.*Safari|Safari.*Mobile|MobileSafari/,
  ie: /IEMobile|MSIEMobile/,
  firefox: /fennec|firefox.*maemo|(Mobile|Tablet).*Firefox|Firefox.*Mobile|FxiOS/,
};

function browser(): string {
  for (const [key, regex] of Object.entries(BROWSER)) {
    if (regex.test(this.ua)) {
      return key;
    }
  }

  return 'other';
}

function isInApp(): boolean {
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv)'];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(navigator.userAgent.match(regex));
}

export function fixReactVSBrowserExtensions() {
  // Fix browser extensions inserting code in <html></html>
  // https://github.com/facebook/react/issues/24430
  document
    .querySelectorAll(
      'html > *:not(body, head), script[src*="extension://"], link[href*="extension://"], script[src*="scr.kaspersky-labs.com"], link[href*="scr.kaspersky-labs.com"]',
    )
    .forEach((s) => {
      s.parentNode.removeChild(s);
    });
  for (const child of Array.from(document.head.childNodes)) {
    // Remove everything before our first tag
    if ((child as HTMLElement).dataset?.first === 'true') {
      break;
    }
    child.remove();
  }

  if (isInApp()) {
    document.querySelector('script#context-script').innerHTML = '';
  }
}

let hydrationErrorsCount = 0;
export function logHydrationErrors(logger: Logger, error: unknown, errorInfo: ErrorInfo) {
  if (
    error &&
    ((error as Error).message ===
      'Hydration failed because the initial UI does not match what was rendered on the server.' ||
      (error as Error).message?.startsWith('Minified React error #418'))
  ) {
    if (hydrationErrorsCount === 0) {
      logger.error(error, errorInfo);
    }
    hydrationErrorsCount++;
  }
}
