import { InMemoryCacheConfig } from '@apollo/client/cache/inmemory/types.js';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client/index.js';
import { onError } from '@apollo/client/link/error/index.js';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions/index.js';
import { getMainDefinition } from '@apollo/client/utilities/index.js';
import { getLogger } from '@engined/core/services/logger.js';
import { createClient } from 'graphql-ws';
import { v4 as uuidv4 } from 'uuid';

const logger = getLogger('@client/services/apollo');

export type Client = ApolloClient<unknown>;

export default function createApollo(
  uri: string,
  cacheState?: NormalizedCacheObject,
  cacheConfig?: InMemoryCacheConfig,
  csrfToken?: string,
  credentials: 'include' | 'same-origin' = 'same-origin',
): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache(cacheConfig);
  const windowId = uuidv4();

  if (cacheState) {
    cache.restore(cacheState);
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (
      networkError &&
      !networkError.message.includes('Socket closed') &&
      !networkError.message.includes('Load failed') &&
      !networkError.message.includes('Failed to fetch')
    ) {
      logger.error(networkError);
    }
  });

  const httpLink = new HttpLink({
    uri,
    credentials,
    headers: {
      'CSRF-Token': csrfToken,
      'Window-ID': windowId,
    },
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${uri.replace(/^http/, 'ws')}`,
      connectionParams: () => ({
        'Window-ID': windowId,
      }),
      lazy: true,
      lazyCloseTimeout: 30_000,
    }),
  );

  const endpointLink = ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const link = ApolloLink.from([errorLink, endpointLink]);

  return new ApolloClient({
    cache,
    link,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    // https://github.com/apollographql/apollo-client/issues/4814
    ssrForceFetchDelay: 1000,
  });
}
