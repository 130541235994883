import { LocaleContextValue } from '@engined/client/contexts/LocaleContext.js';
import { useSSR } from '@engined/client/contexts/SSRContext.js';
import type { AssetsManifest } from '@engined/dev/build/manifest.js';
import { AgnosticRouteObject } from '@remix-run/router';
import React, { ComponentType, ReactNode, useCallback, useEffect } from 'react';
import { RouteObject } from 'react-router';
import { Location, matchRoutes, Params } from 'react-router-dom';

export interface RouteManifest<Route> {
  [routeId: string]: Route;
}

interface Route {
  caseSensitive?: boolean;
  id: string;
  path?: string;
  index?: boolean;
}

export interface RouteModules {
  [routeId: string]: RouteModule;
}

export type AppData = any;

export type CatchBoundaryComponent = ComponentType;
export type ErrorBoundaryComponent = ComponentType<{ error: Error }>;

export interface HtmlMetaDescriptor {
  charset?: 'utf-8';
  charSet?: 'utf-8';
  title?: string;
  [name: string]: null | string | undefined | Record<string, string> | Array<Record<string, string> | string>;
}

export interface MetaFunction {
  (args: {
    data: AppData;
    parentsData: { [routeId: string]: AppData };
    params: Params;
    location: Location;
    locale: LocaleContextValue;
  }): HtmlMetaDescriptor | undefined;
}

export type RouteComponent = ComponentType;

// export interface LinksFunction {
//   (): LinkDescriptor[];
// }

export type RouteHandle = any;

export interface RouteModule {
  CatchBoundary?: CatchBoundaryComponent;
  ErrorBoundary?: ErrorBoundaryComponent;
  default: RouteComponent;
  handle?: RouteHandle;
  // links?: LinksFunction;
  meta?: MetaFunction | HtmlMetaDescriptor;
}

// NOTE: make sure to change the EntryRoute in server-runtime if you change this
export interface EntryRoute extends Route {
  // hasAction: boolean;
  // hasLoader: boolean;
  hasCatchBoundary: boolean;
  hasErrorBoundary: boolean;
  imports?: string[];
  module: string;
  parentId?: string;
}

type RouteComponentType = ComponentType<{ id: string; loader?: React.ReactNode }>;

export function createClientRoutes(
  routeManifest: RouteManifest<EntryRoute>,
  routeModulesCache: RouteModulesCache,
  Component: RouteComponentType,
  parentId?: string,
): ClientRoute[] {
  return Object.keys(routeManifest)
    .filter((key) => routeManifest[key].parentId === parentId)
    .map((key) => {
      const route = createClientRoute(routeManifest[key], routeModulesCache, Component);
      const children = createClientRoutes(routeManifest, routeModulesCache, Component, route.id);
      if (children.length > 0) route.children = children;
      return route;
    });
}

export type RouteDataFunction = {
  (): Promise<any> | any;
};

export interface ClientRoute extends Route {
  loader?: RouteDataFunction;
  ErrorBoundary?: any;
  CatchBoundary?: any;
  children?: ClientRoute[];
  element: ReactNode;
  module: string;
}

export function createClientRoute(
  entryRoute: EntryRoute,
  routeModulesCache: RouteModulesCache,
  Component: RouteComponentType,
): ClientRoute {
  const loader = createLoader(entryRoute, routeModulesCache);
  const LazyComponent = React.lazy(loader);
  return {
    caseSensitive: !!entryRoute.caseSensitive,
    element: <Component id={entryRoute.id} loader={<LazyComponent />} />,
    id: entryRoute.id,
    path: entryRoute.path,
    index: entryRoute.index,
    module: entryRoute.module,
    ErrorBoundary: entryRoute.hasErrorBoundary,
    CatchBoundary: entryRoute.hasCatchBoundary,
    loader,
  };
}

function createLoader(route: EntryRoute, routeModules: RouteModulesCache) {
  return async () => {
    const routeModule = await loadRouteModule(route, routeModules);
    return routeModule;
  };
}

async function loadRouteModule(
  route: EntryRoute | ClientRoute,
  routeModulesCache: RouteModulesCache,
): Promise<RouteModule> {
  let routeModule = routeModulesCache.get(route.id);
  if (routeModule) {
    return routeModule;
  }

  try {
    routeModule = (await import(route.module)) as RouteModule;
    routeModulesCache.set(route.id, routeModule);
    return routeModule;
  } catch (error) {
    // User got caught in the middle of a deploy and the CDN no longer has the
    // asset we're trying to import! Reload from the server and the user
    // (should) get the new manifest--unless the developer purged the static
    // assets, the manifest path, but not the documents 😬
    window.location.reload();
    return new Promise(() => {
      // check out of this hook cause the DJs never gonna re[s]olve this
    });
  }
}

type RouteModulesCacheSubscriber = (module: RouteModule) => void;
export class RouteModulesCache {
  private subscribers: RouteModulesCacheSubscriber[] = [];

  constructor(private cache: RouteModules) {}

  public set(routeId: string, module: RouteModule) {
    this.cache[routeId] = module;
    for (const subscriber of this.subscribers) {
      subscriber(module);
    }
  }

  public get(routeId: string) {
    if (routeId in this.cache) {
      return this.cache[routeId];
    }
    return null;
  }

  public subscribe(subscriber: RouteModulesCacheSubscriber) {
    this.subscribers.push(subscriber);
  }
}

export function createRoutes<TContext>(context: TContext, manifest: AssetsManifest, parentId?: string): RouteObject[] {
  const routes = manifest.routes;
  return Object.keys(routes)
    .filter((key) => routes[key].parentId === parentId)
    .map((id) => {
      const children = createRoutes(context, manifest, id);
      return {
        ...routes[id],
        lazy: async () => {
          const module = await import(routes[id].module);
          return {
            ...module,
            loader: module.loader ? (arg) => module.loader({ ...arg, context }) : null,
            element: React.createElement(module.default),
            hasErrorBoundary: !!module.ErrorBoundary,
            ErrorBoundary: undefined,
            errorElement: module.ErrorBoundary ? React.createElement(module.ErrorBoundary) : null,
          };
        },
        children: children.length ? children : undefined,
      } as RouteObject;
    });
}

export function usePreload(path: string | string[]) {
  const lazyPreload = useLazyPreload(path);
  useEffect(() => {
    lazyPreload();
  }, [lazyPreload]);
}

export function useLazyPreload(path: string | string[]) {
  const { routes } = useSSR();
  return useCallback(() => {
    if (Array.isArray(path)) {
      for (const p of path) {
        const matches = matchRoutes(routes as unknown as AgnosticRouteObject[], p.replace(':', ''));
        if (matches) {
          for (const match of matches) {
            const route = match.route;
            route.lazy?.();
          }
        }
      }
    } else if (path) {
      const matches = matchRoutes(routes as unknown as AgnosticRouteObject[], path.replace(':', ''));
      if (matches) {
        for (const match of matches) {
          const route = match.route;
          route.lazy?.();
        }
      }
    }
  }, [path, routes]);
}
