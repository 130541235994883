import { TypePolicies } from '@apollo/client/index.js';

const typePolicies: TypePolicies = {
  Settings: {
    keyFields: ['key'],
  },
  User: {
    fields: {
      tasks: {
        merge(existing = [], incoming: any[]) {
          return incoming;
        },
      },
    },
  },
  OrderReceived: {
    fields: {
      tasks: {
        merge(existing = [], incoming: any[]) {
          return incoming;
        },
      },
      expeditionAssignedTo: {
        merge(existing = [], incoming: any[]) {
          return incoming;
        },
      },
    },
  },
  PhaseGroup: {
    fields: {
      phases: {
        merge(existing, incoming, { mergeObjects, readField }) {
          const merged = incoming ? incoming.slice(0) : [];
          const incommingIdSet = new Set(merged.map((task) => readField('id', task)));
          if (existing) {
            merged.push(...existing.filter((e) => !incommingIdSet.has(readField('id', e))));
          }
          merged.sort((a, b) => {
            return (readField('position', a) as number) - (readField('position', b) as number);
          });
          return merged;
        },
      },
    },
  },
};

export default typePolicies;
