import { NormalizedCacheObject, PossibleTypesMap, TypePolicies } from '@apollo/client/index.js';
import createApollo from '@engined/client/services/apollo.js';

export default (
  uri: string,
  cacheState?: NormalizedCacheObject,
  possibleTypes?: PossibleTypesMap,
  csrfToken?: string,
  credentials: 'include' | 'same-origin' = 'same-origin',
  typePolicies: TypePolicies = {},
) =>
  createApollo(
    uri,
    cacheState,
    {
      possibleTypes,
      typePolicies,
    },
    csrfToken,
    credentials,
  );
