import { ApolloProvider } from '@apollo/client/index.js';
import typePolicies from '@asaprint/asap/typePolicies.js';
import createApollo from '@asaprint/common/services/apollo.js';
import { LoaderFunctionContext } from '@engined/core/interfaces.js';
import possibleTypes from '@asaprint/asap/possibleTypes.json';
import { CacheProvider } from '@emotion/react';
import SSRContext, { SSRContextValue } from '@engined/client/contexts/SSRContext.js';
import { fixReactVSBrowserExtensions, logHydrationErrors } from '@engined/client/helpers/react.js';
import { createRoutes } from '@engined/client/routes.js';
import { createCache as createEmotionCache } from '@engined/core/services/emotion.js';
import { connectSentry, getLogger, LEVELS } from '@engined/core/services/logger.js';
import { config } from '@fortawesome/fontawesome-svg-core';
import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  createBrowserRouter as rrCreateBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

let createBrowserRouter = rrCreateBrowserRouter;
const emotionCache = createEmotionCache();

const context = window.__context as SSRContextValue;

const graphqlEndpoint = `https://${ENV.ASAP_HOSTNAME}/graphql`;

if (ENV.ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    release: ENV.SENTRY_RELEASE,
    environment: ENV.SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        shouldCreateSpanForRequest: (reqUrl) => {
          return reqUrl.includes(ENV.ASAP_HOSTNAME);
        },
        routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [graphqlEndpoint],
      }),
    ],
    tracesSampleRate: ENV.SENTRY_BROWSER_TRACES_SAMPLE_RATE,
    tracePropagationTargets: [ENV.ASAP_HOSTNAME],
    replaysSessionSampleRate: ENV.SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: ENV.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  });

  Sentry.setTag('application', 'asap');
  connectSentry(Sentry, LEVELS.WARN);

  createBrowserRouter = SentryReact.wrapCreateBrowserRouter(createBrowserRouter);
}

config.autoAddCss = false;

const logger = getLogger('@asaprint/asap');

const apollo = createApollo(
  graphqlEndpoint,
  context.apolloCache as any,
  possibleTypes.possibleTypes,
  context.csrfToken,
  'same-origin',
  typePolicies,
);

async function hydrate() {
  const routerRoutes = createRoutes<LoaderFunctionContext>(
    {
      apollo,
    },
    context.manifest,
  );
  context.routes = routerRoutes;

  // Determine if any of the initial routes are lazy
  const lazyMatches = matchRoutes(routerRoutes, window.location)?.filter((m) => m.route.lazy);

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      }),
    );
  }

  const router = createBrowserRouter(routerRoutes);

  fixReactVSBrowserExtensions();

  const root = hydrateRoot(
    document,
    <React.StrictMode>
      <SSRContext.Provider value={context}>
        <ApolloProvider client={apollo}>
          <CacheProvider value={emotionCache}>
            <RouterProvider router={router} />
          </CacheProvider>
        </ApolloProvider>
      </SSRContext.Provider>
    </React.StrictMode>,
    {
      onRecoverableError: (error, errorInfo) => {
        logHydrationErrors(logger, error, errorInfo);
      },
    },
  );

  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker.register('/service-worker.js');
  }
}

hydrate();
